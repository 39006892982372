import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { appVersion } from '../app.component';
import { selectIp, selectUser } from '../ngrx/data.reducer';
import { HelperService } from './helper.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements OnDestroy {
  ipData: any;
  user: any;
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpService,
    private store: Store,
    private router: Router,
    private helper: HelperService
  ) {
    this.store.select(selectIp)
      .pipe(
        takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe(ip => this.ipData = ip);
    this.store.select(selectUser)
      .pipe(
        takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe(user => this.user = user);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private trackEvent(eventType: string, data: any) {
    const cleanData = this.removeEmptyProperties(data);
    try {
      this.http
        .post(`api/Home/${eventType}`, cleanData, false, false)
        .pipe(takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe(() => { });
    } catch (error) {
      console.error('An error occurred while tracking event:', error);
    }
  }

  private removeEmptyProperties(obj: any): any {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== '')
    );
  }

  private returnUpdatedProductDetails(detail: string): string {
    const cleanedDetail = (detail || '').trim().toLocaleLowerCase();
    switch (cleanedDetail) {
      case 'students only - 15 min':
      case 'only students - 15 min':
      case 'standard - 15 min':
        return '15 Minutes';
      case 'students only - 30 min':
      case 'only students - 30 min':
      case 'advance - 30 min':
        return '30 Minutes';
      case 'audio':
        return 'Voice Chat';
      case 'text':
        return 'Text Chat';
      case 'video':
        return 'Video Chat';
      default:
        return detail || '';
    }
  }
  trackPageVisit(
    apiPath: string = null,
    uniqueName: string = null,
    pageName: string = null,
    ParentPage: string = null,
    ChildPage: string = null,
    SubChildPage: string = null
  ): void {
    const replaceSpecialChars = (str: string) => str ? str.replace(/[-&]/g, (match: string) => (match === '&' ? ' & ' : ' ')) : '';
    const data = this.removeEmptyProperties({
      userId: this.user?.id || 0,
      url: window.location.href,
      pageName: this.helper.capitalizeFirstLetter(pageName) || this.router.url,
      uniqueName,
      categoryName: this.router.url.includes('creators')
        ? this.helper.capitalizeFirstLetter(replaceSpecialChars(this.router.url.split('/')[2]))
        : null,
      ParentPage: this.helper.capitalizeFirstLetter(ParentPage) || this.router.url,
      ChildPage: this.helper.capitalizeFirstLetter(replaceSpecialChars(ChildPage)) || this.router.url,
      SubChildPage: this.helper.capitalizeFirstLetter(replaceSpecialChars(SubChildPage)) ||
        (ParentPage || ChildPage ? 'All' : null),
      loggedInAs: this.helper.capitalizeFirstLetter(this.user?.userType) || 'Visitor',
      ...this.helper.returnExtraDetails()
    });
    this.trackEvent(apiPath || 'mixpanel-page-visit-event', data);
  }
  trackProductEvent(
    eventType: string,
    creatorName: string,
    product: string,
    productDetails: string,
    amount: number,
    username: string,
    paymentGateway?: string,
    paymentMethod?: string
  ): void {
    const data = this.removeEmptyProperties({
      ip: this.ipData?.ip,
      userId: this.user?.id || 0,
      username,
      url: window.location.href,
      operatingSystem: this.helper.capitalizeFirstLetter(this.helper.detectOS()),
      appVersion,
      device: this.helper.capitalizeFirstLetter(this.helper.detectOS()),
      source: 'Browser',
      loggedInAs: this.helper.capitalizeFirstLetter(this.user?.userType) || 'Visitor',
      browser: this.helper.capitalizeFirstLetter(this.helper.detectBrowserName()),
      creatorName,
      product: product.toLowerCase() === 'emeet' || product?.toLowerCase() === 'emeeting' ? 'eMeeting' : this.helper.capitalizeFirstLetter(product),
      productDetails: this.helper.capitalizeFirstLetter(this.returnUpdatedProductDetails(productDetails)),
      amount,
      paymentGateway: this.helper.capitalizeFirstLetter(paymentGateway),
      paymentMethod: this.helper.capitalizeFirstLetter(paymentMethod),
    });
    this.trackEvent(eventType, data);
  }
}
